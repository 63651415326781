<template>
 <div class="box box-primary">
  <div class="box-header with-border">
    <i class="fa fa-envelope"></i>
    <h3 class="box-title text-right">New Mailing Message</h3>
  </div>
  <div class="box-body">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Message Name</label><input iservice-id="Name" type="text" class="form-control has-error"><!--v-if-->
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group"><label class="control-label text-left">Description</label><input iservice-id="Description" type="text" class="form-control"></div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Subject</label><input iservice-id="Name" type="text" class="form-control  has-error"><!--v-if-->
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="control-label text-left">Topic</label>
                    <div class=" has-error">
                      <Multiselect
                                    v-model="value"
                                    placeholder="-Select Topic-"
                                    label="name"
                                    :groups="true"
                                    :searchable="true"
                                    :options="[
                                        {
                                        label: '_iService',
                                        options:  [
                                            { value: 'All Topic', name: 'All Topic',level:'1'},
                                            { value: 'Feedback', name: 'Feedback',level:'2'},
                                            
                                        ],
                                        }
                                    ]"
                                    >
                                    <template v-slot:option="{ option }">
                                    <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                    </template>
                                </Multiselect>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label class="col-12 control-label text-left">Include Ref Number In Subject Line</label>
                    <div>
                        <div class="iservice-switch" iservice-id="Shown"><label class="switch"><input type="checkbox" iservice-switch=""><i></i> </label></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="box box-info">
                    <div class="box-header with-border">
                        <i class="fa fa-file"></i>
                        <h3 class="box-title">Plain Text Message <span class="data-v-tooltip" data-v-tooltip="Your message should include a plain text and HTML version. Although most users can view HTML email, there are some scenarios where the plain text version will be displayed by default. Be sure to only use the plain text version of any $link commands." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></h3>
                        <div class="btn-group pull-right variable-btn">
                        <Variables></Variables>
                        </div>
                    </div>
                    <div class="box-body">
                        <textarea id="BodyPlain" iservice-id="BodyPlain" class="form-control" rows="17"></textarea>
                        <!--v-if-->
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="box box-info">
                    <div class="box-header with-border">
                        <i class="fa fa-file"></i>
                        <h3 class="box-title">Rich Text Message <span class="data-v-tooltip" data-v-tooltip="If you've prepared the HTML version of your message in a separate editor, toggle the editor to Source view and paste the contents into that panel. Use the save button after you toggle back to the preview mode." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);"><i class="fa fa-question-circle text-info help-icon"></i></span></h3>
                        <div class="btn-group pull-right variable-btn">
                        <VariableAndStockResponses></VariableAndStockResponses>
                        </div>
                    </div>
                    <div class="box-body">
                        <textarea id="BodyPlain" iservice-id="BodyPlain" class="form-control" rows="17"></textarea>
                        <!--v-if-->
                    </div>
                </div>
            </div>
        </div>
        <div class="row"><div class="col-2"><button iservice-id="Save" class="btn btn-primary">Save</button></div><div class="col-10 admin-error"><!--v-if--></div></div>
      
  </div>
</div>
</template>

<style src="@vueform/toggle/themes/default.css"></style>
<script>
import Multiselect from '@vueform/multiselect'
import Vue from 'vue';
import Toggle from '@vueform/toggle'
import VariableAndStockResponses from '../components/VariableAndStockResponses.vue';
import Variables from '../components/Variables.vue';
import { reactive,ref, onMounted } from 'vue'
export default {
  name: 'Department Settings',
   components: {
    Variables,
    VariableAndStockResponses,
    Multiselect,
  },
  setup(){      
      
       return{};
  }

}
</script>
